import React from 'react';
import '../styles/HeroSection.css';

const LoginWelcomeStatement = () => {
  return (
    <section className="section-dark-text">
      
       <h2 className="dark-title">
         Swift, <span className="dark-highlight">Reliable</span>, 
         and <span className="dark-highlight">Borderless</span> 
         Transactions for <span className="dark-highlight">Everyone</span>
       </h2>

    </section>
  );
};

export default LoginWelcomeStatement;
