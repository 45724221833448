import React from 'react';
import '../styles/Footer.css';
import { FaCoins } from 'react-icons/fa';
import logoIcon   from "../assets/logo.png";

import linkedInIcon   from "../assets/other/linkedIn.svg";
import facebookIcon   from "../assets/other/facebook.svg";
import tiktokIcon   from "../assets/other/tiktok.svg";
import instagramIcon   from "../assets/other/instagram.svg";
import xIcon   from "../assets/other/x.svg";

const Footer = () => {
  return (
    <div className="footer group clearfix">

      <div className="footer-logo">
        <img src={logoIcon} alt="peerpesa" className="footer-snap" />   
      </div>

      <div className="column_100 flexing_content flex_container group clearfix">
           
        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
            <h3> Quick Links </h3>
            <ul>
              <li><a href="#">About </a></li>
              <li><a href="#">Support</a></li>
              <li><a href="#">Careers</a></li>
            </ul>
          </div>  
        </div>

        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
          <h3> Products </h3>
            <ul>
              <li><a href="#">Money transfer </a></li>
              <li><a href="#">Buy Crypto</a></li>
              <li><a href="#">P2P Trading</a></li>
            </ul>
          </div>   
        </div>

        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
          <h3> Legal </h3>
            <ul>
            <li><a href="#">Customer Protection Policy </a></li>
                <li><a href="#">AML Policy </a></li>
                <li><a href="#">Terms of Service</a></li>
                <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div> 
        </div>

        <div className="column_25 margin-bottom-30">
          <div className="column_100 footer_content">
          <h3> Our Community </h3>
          <ol>
              <li> <img src={linkedInIcon} alt="linked" className="footer-social-snap" /> </li>
              <li> <img src={facebookIcon} alt="facebook" className="footer-social-snap" /> </li>
              <li> <img src={tiktokIcon} alt="tiktok" className="footer-social-snap" /> </li>
              <li> <img src={instagramIcon} alt="instagram" className="footer-social-snap" /> </li>
              <li> <img src={xIcon} alt="x" className="footer-social-snap" /> </li>
          </ol>
          </div> 
        </div>

      </div>

      <div className="column_100"></div>
    </div>
  );
};

export default Footer;
