import React from 'react';
import '../styles/HeroSection.css';

const HeroSection = () => {
  return (
    <section className="section-inner">
      
      <h2 className="title">
        Swift, <span className="highlight">Reliable</span>, and <span className="highlight">Borderless</span> Transactions for <span className="highlight">Everyone</span>
      </h2>
      <p className="text small-hide">Effortless Money Transfer Experience powered by blockchain. Move money across borders effortlessly straight to mobile wallets and bank accounts Across Borders.</p>
    </section>
  );
};

export default HeroSection;
