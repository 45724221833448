import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import '../styles/TransferForm.css';
const LoginTransferForm = () => {
  
  //const navigate = useNavigate();
  const [currencyCollection, setCurrencyCollection] = useState({
                                                currencyOptions: [
                                                      { value: '1', 
                                                        label: 'Exchange' },
                                                      { value: '2', 
                                                        label: 'Mobile Money' },
                                                      { value: '3', 
                                                        label: 'Bank' }
                                                    ],
                                                buyCurrencyFilter: [],
                                                sellCurrencyFilter: [],
                                                currencyCountryFlagsCollection: [
                                                      {
                                                        value: 'ugx',
                                                        label: 'UGX',
                                                        imageUrl: 'https://www.worldometers.info/img/flags/ug-flag.gif',
                                                      },
                                                      {
                                                        value: 'kes',
                                                        label: 'KES',
                                                        imageUrl: 'https://www.worldometers.info/img/flags/ke-flag.gif',
                                                      }
                                                    ],
                                                currencyFlagsCollection: [
                                                      {
                                                        value: 'usdt',
                                                        label: 'USDT',
                                                        imageUrl: 'https://cryptologos.cc/logos/tether-usdt-logo.png?v=035',
                                                      },
                                                      {
                                                        value: 'btc',
                                                        label: 'BTC',
                                                        imageUrl: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=035',
                                                      },
                                                      {
                                                        value: 'eth',
                                                        label: 'ETH',
                                                        imageUrl: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=035',
                                                      },
                                                      {
                                                        value: 'sol',
                                                        label: 'SOLANA',
                                                        imageUrl: 'https://cryptologos.cc/logos/solana-sol-logo.png?v=035',
                                                      },
                                                      {
                                                        value: 'usdc',
                                                        label: 'USDC',
                                                        imageUrl: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035',
                                                      },
                                                      {
                                                        value: 'tron',
                                                        label: 'TRON',
                                                        imageUrl: 'https://cryptologos.cc/logos/tron-trx-logo.png?v=035',
                                                      }
                                                    ]
                                             });    
  const changeDefaultCurreny = () => {
      
  }   
  
  
  
  // Custom option label with image and text
  const formatOptionLabel = ({ label, imageUrl }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={imageUrl} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
        <span>{label}</span>
      </div>
  );
    
  return (
    <div className="card-login">
      <form className="form">

        <div className="text-center-content">
           <h3 className="text-center">Log into Your PeerPesa Account</h3> 
        </div>   
        <div className="form-inputs">
          <label> Email  </label>
          <div className="selection-payment-method clearfix group">
            <input type="text" value="d8paul" className="selection-other"  placeholder="" />
          </div>
          
        </div>
        <div className="form-inputs">
          <label> Password </label>
          <div className="selection-payment-method clearfix group">
            <input type="password" value="" className="selection-other"  placeholder="******"/>
          </div>
        </div>

        <div className="text-center-content-2">
           <p className="text-left">
              Forgot password? <Link to="/reset-password" className="nav-link">Reset it here</Link>
           </p> 
        </div>

        
        <button className="send-button">
           <span>Login</span>
        </button>

        
        <div className="text-center-content-2">
           <p className="text-center">
             Dont Have An Account? <Link to="/register" className="nav-link">Signup here</Link> 
           </p> 
        </div>


        
        <div className="text-center-content-2">
           <p className="text-center">
               &copy; PeerPesa 2024 All Rights Reserved
           </p> 
        </div>

        
      </form>
    </div>
  );
};

export default LoginTransferForm;
