import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Dashboard.css';
import Header from '../components/Header';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LoggedBanner from '../components/LoggedBanner';
function Dashboard() {

  const navigate = useNavigate();
  
  const navigating = (route) => {
    navigate(route); // Navigate to the About page
  }


  return (
    <div className="App">
      
      <Header />
      
      <LoggedBanner />

      <div className="logged-dashboard-width flexing_content flex_container nopaddding-small">
        <div className="main-dashboard-section column_40 margin-bottom-30"> 
          <div className="main-dashboard-section-inner dark-blue-background group clearfix">
            <div className="main-dashboard-section-title group clearfix">

              <h4 className="title white-text text-left">
                 My Assets
                 <span className="pull-right more-content" onClick={(e) => navigating('/asssets')}> See All</span>
              </h4>
              <Table className="the-table-content">
                  <Thead>
                    <Tr>
                      <Th>Asset</Th>
                      <Th>Amount</Th>
                      <Th>Cost Price</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Cusd</Td>
                      <Td>200</Td>
                      <Td>$1.23</Td>
                      <Td  className="text-right"><span className="button">Options</span></Td>
                    </Tr>
                    <Tr>
                      <Td>Usdt</Td>
                      <Td>1000</Td>
                      <Td>$1.23</Td>
                      <Td className="text-right"><span className="button">Options</span></Td>
                    </Tr>
                    <Tr>
                      <Td>BTC</Td>
                      <Td>5</Td>
                      <Td>$98703</Td>
                      <Td className="text-right"><span className="button">Options</span></Td>
                    </Tr>
                  </Tbody>
                </Table>
            </div>
          </div> 
        </div>

        <div className="main-dashboard-section column_60 margin-bottom-30">
          <div className="main-dashboard-section-inner dark-blue-background group clearfix">
            <div className="main-dashboard-section-title group clearfix">
              <h4 className="title white-text text-left">
                 Recent Transactions
                 <span className="pull-right more-content"  onClick={(e) => navigating('/transactions')}> See All</span>
              </h4>
              <Table className="the-table-content">
                <Thead>
                  <Tr>
                      <Th>Crypto</Th>
                      <Th>Network</Th>
                      <Th>Time</Th>
                      <Th>Status</Th>
                      <Th>Amount</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        TRX
                      </Td>
                      <Td>
                        <p>TRX</p>
                        <p>Tron(TRC20)</p>
                      </Td>
                      <Td>9 April 2019</Td>
                      <Td>Withdrawal Successful</Td>
                      <Td>62.56</Td>
                      <Td><span className="button">Details</span></Td>
                    </Tr>
                    <Tr>
                      <Td>
                        Solana
                      </Td>
                      <Td>
                        <p>SOL</p>
                        <p>Sol network</p>
                      </Td>
                      <Td>19 April 2019</Td>
                      <Td>Withdrawal Successful</Td>
                      <Td>62.56</Td>
                      <Td><span className="button">Details</span></Td>
                    </Tr>
                  </Tbody>
                </Table>
            </div>
          </div> 
        </div>
      </div>

    </div>
  );
}

export default Dashboard;
