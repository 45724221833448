import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import '../styles/TransferForm.css';

const ResetPasswordTransferForm = () => {

  //const navigate = useNavigate();
  const [resendToken, setResendToken] = useState(false);
  const [currencyCollection, setCurrencyCollection] = useState({
                                                currencyOptions: [
                                                      
                                                    ],
                                                buyCurrencyFilter: [],
                                                sellCurrencyFilter: [],
                                                currencyCountryFlagsCollection: [
                                                    ],
                                                currencyFlagsCollection: [
                                                      
                                                    ]
                                             });    
  const changeDefaultCurreny = () => {
      
  }   

  // Custom option label with image and text
  const formatOptionLabel = ({ label, imageUrl }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={imageUrl} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
        <span>{label}</span>
      </div>
  );
    
  return (
    <div className="card-login">
      <form className="form">

        <div className="text-center-content">
           <h3 className="text-center">Reset Your PeerPesa Account Password</h3> 
        </div>   
        <div className="form-inputs">
          <label> Email  </label>
          <div className="selection-payment-method clearfix group">
            <input type="text" value="d8paul" className="selection-other"  placeholder="" />
          </div>
        </div>

        <div className="form-inputs">
          <label> Verification Token  </label>
          <div className="flexing_content flex_container reset-token-width">

            <div className="selection-payment-method clearfix group width-40">
              <input type="text" value="*****" className="selection-other"  placeholder="" />
            </div>

            <button className="send-button  width-55">
              <span>Resend Token</span>
            </button>
          </div>
        </div>

       {resendToken? <>
          
        <div className="form-inputs">
          <label> Set a Password </label>
          <div className="selection-payment-method clearfix group">
            <input type="password" value="" className="selection-other"  placeholder="******"/>
          </div>
        </div>
        
        <div className="form-inputs">
          <label> Confirm Password </label>
          <div className="selection-payment-method clearfix group">
            <input type="password" value="" className="selection-other"  placeholder="******"/>
          </div>
        </div>
       
       </>:''} 
        

        <button className="send-button">
           <span>Save Account Password</span>
        </button>

        <div className="text-center-content-2">
           <p className="text-center">
                Have an Account Already? <Link to="/login" className="nav-link">Login here</Link>
           </p> 
        </div>



        <div className="text-center-content-2">
           <p className="text-center">
               &copy; PeerPesa 2024 All Rights Reserved
           </p> 
        </div>

        
      </form>
    </div>
  );
};

export default ResetPasswordTransferForm;
