import React from 'react';
import '../styles/MainSection.css';
import { useNavigate, Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Header from '../components/Header';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../styles/MainSection.css';

import depositIcon   from "../assets/other/dashboard/deposit.svg";
import withdrawIcon  from "../assets/other/dashboard/withdraw.svg";
import transferIcon  from "../assets/other/dashboard/transfer.svg";



function Orders() {

  const handleSelect = (date) => {

    console.log(date); // native Date object
  }

 
    
  return (
    <div className="App">
      <Header />
      
      <div className="logged-dashboard-width flexing_content flex_container nopaddding-small">
        <div className="main-dashboard-section column_100 margin-bottom-30">
          <div className="main-dashboard-section-inner dark-blue-background group clearfix">
            <div className="main-dashboard-section-title group clearfix">
              
              <h4 className="title white-text text-left">
                Orders
              </h4>

              <div className="group clearfix flexing_content flex_container content-dash-buttons-2">
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/deposit')}>
                   <img src={depositIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon rotate-90"/>
                   <p className="the-button-here-selected-text">Open Trades</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/withdraw')}>
                   <img src={withdrawIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon rotate-270"/>
                   <p className="the-button-here-selected-text">Closed Trades</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/transfer')}>
                   <img src={transferIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon the-button-here-selected-icon-active"/>
                   <p className="the-button-here-selected-text">Trade History </p> 
                 </div>
               </div>
              </div>

              <div className="group clearfix flexing_content flex_container content-dash-buttons-2">
              </div>        
              
              <Table className="the-table-content">
                <Thead>
                  <Tr>
                      <Th>Date</Th>
                      <Th>Pair</Th>
                      <Th>Type</Th>
                      <Th>Side</Th>
                      <Th>Price	Amount</Th>
                      <Th>Amount</Th>
                      <Th>Filled</Th>
                      <Th>Total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>19 April 2019</Td>
                      <Td><p>SOL/USDT</p></Td>
                      <Td>Withdraw</Td>
                      <Td>19 April 2019</Td>
                      <Td><p className="the-wallet">f4184fc596403b9d638783cf57adfe4c75c605f6356fbc91338530e9831e9e16</p></Td>
                      <Td><p className="the-wallet">30000</p></Td>
                      <Td><p className="the-wallet">30/100</p></Td>
                      <Td>62.56</Td>
                    </Tr>
                  </Tbody>
                </Table>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default Orders;
