import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import '../styles/LoggedBanner.css';
import eyeOpenIcon   from "../assets/other/dashboard/eye-off.svg";
import eyeIcon   from "../assets/other/dashboard/eye.svg";
import expandIcon   from "../assets/other/dashboard/arrow-down.svg";

import depositIcon   from "../assets/other/dashboard/deposit.svg";
import withdrawIcon  from "../assets/other/dashboard/withdraw.svg";
import transferIcon  from "../assets/other/dashboard/transfer.svg";
import sendIcon      from "../assets/other/dashboard/send.svg";


const LoggedBanner = () => {
  
  const [mobileMenu, setMobileMenu] = useState(false);
  const [userMenu,   setUserMenu] = useState(false);
  const [showBalance,   setShowBalance] = useState(false);
  const [showBalanceAs,   setShowBalanceAs] = useState(false);


  const navigate = useNavigate();
  const goTo = (route) => {
    navigate(route); // Navigate to the About page
  };

  const [scrollYPosition, setScrollYPosition] = React.useState(0);
  
  const handleScroll = () => {
    setShowBalanceAs(false)
  };

  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {

        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const showAccountDetailsTab = () => {
    setMobileMenu(false);
    setUserMenu(!userMenu)
  }

  const showMobileMenuDetailsTab = () => {
     setUserMenu(false)
     setMobileMenu(!mobileMenu)
  }


  const showingExpandedBlock = () => {
    setShowBalanceAs(!showBalanceAs)
  }

  const showingHideAmount = () => {
    setShowBalance(!showBalance)
  }

  const navigating = (route) => {
    navigate(route); // Navigate to the About page
  }

  return (
    <div className="logged-dashboard logged-dashboard-width">
      <div className="flexing_content flex_container">

        <div className="total-wallet-balance-section column_30 margin-bottom-10">
          <div className="total-wallet-balance dark-blue-background group clearfix">
            <div className="group clearfix">
              <h4 className="white-text text-left">
                 <span  className="sub-title-section"> Total Equity  </span>
                 <span className="closed-eye-border">
                  {showBalance ? 
                     <img src={eyeIcon} alt="expand" onClick={(e) => showingHideAmount(e)} className="closed-eye  white-icon icon-small-width"/>:
                     <img src={eyeOpenIcon} alt="expand" onClick={(e) => showingHideAmount(e)} className="open-eye  white-icon icon-small-width"/>
                  }
                
                 </span>
              </h4>
              <p>
                <span className="sub-amount-section">{!showBalance? `*********`:`0.00000464`}</span>
                <span className="closed-eye-border-open">
                  <img src={expandIcon} alt="expand" onClick={(e) => showingExpandedBlock(e)} className={showBalanceAs? "expand-view white-icon icon-small-width rotate-180": "expand-view white-icon icon-small-width"}/>
                  {!showBalanceAs? 
                       "" : <div className="the-drop-for-options">
                        <p><span className="nav-link-drop-down-option">USDT</span></p>
                        <p><span className="nav-link-drop-down-option">BTC</span></p>
                        <p><span className="nav-link-drop-down-option">CUSD</span></p>
                        <p><span className="nav-link-drop-down-option">STELLAR</span></p>
                      </div>

                  }
                </span>
              </p>
            </div>
          </div> 
        </div>

        <div className="total-wallet-balance-section column_70 margin-bottom-10">
          <div className="total-wallet-balance dark-blue-background group clearfix">
            
            
            <div className="group clearfix flexing_content flex_container content-dash-buttons">


               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/deposit')}>
                   <img src={depositIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon rotate-90"/>
                   <p className="the-button-here-selected-text">Deposit</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/withdraw')}>
                   <img src={withdrawIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon rotate-270"/>
                   <p className="the-button-here-selected-text">Withdraw</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/transfer')}>
                   <img src={transferIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon"/>
                   <p className="the-button-here-selected-text">Transfer</p> 
                 </div>
               </div>

               
               <div className="the-button-here">
                 <div className="the-button-here-selected" onClick={(e) => navigating('/send')}>
                   <img src={sendIcon} alt="expand" className="open-eye  white-icon the-button-here-selected-icon"/>
                   <p className="the-button-here-selected-text">Send</p> 
                 </div>
               </div>



            </div>
          </div> 
        </div>

      </div>  
    </div>

  );
};

export default LoggedBanner;
