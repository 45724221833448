import React from 'react';
import '../styles/MainSection.css';
import Header from '../components/Header';
import RegisterSection from '../components/RegisterSection';

function Register() {
  return (
    <div className="App">
      <Header />
      <RegisterSection />
    </div>
  );
}

export default Register;
